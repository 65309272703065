<template>
  <!--begin::Navbar-->
  <div class="app-navbar flex-shrink-0">
    <!--begin::User menu-->
    <div id="kt_header_user_menu_toggle" class="app-navbar-item ms-1 ms-md-3">
      <!--begin::Menu wrapper-->
      <div
        class="cursor-pointer symbol symbol-30px symbol-md-40px"
        data-kt-menu-trigger="click"
        data-kt-menu-attach="parent"
        data-kt-menu-placement="bottom-end"
      >
        <span class="bg-light-danger text-primary symbol-label fw-bold">
            {{ user.name.substring(0, 1) + user.surname.substring(0, 1) }}
        </span>
      </div>
      <LUserMenu />
      <!--end::Menu wrapper-->
    </div>
    <!--end::User menu-->
  </div>
  <!--end::Navbar-->
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { getAssetPath } from 'assets/theme/metronic/ts/helpers/assets';
import LUserMenu from '@/components/theme/metronic/layout/header/navbar/menus/UserAccountMenu.vue';
import {useAuthStore} from "~/stores/auth";
import {storeToRefs} from "pinia";

export default defineComponent({
  name: 'ThemeHeaderNavbar',
  components: {
    LUserMenu,
  },
  setup() {
    const store = useAuthStore();
    const { user } = storeToRefs(store);

    return {
      user,
      getAssetPath
    };
  }
});
</script>
